import React from 'react';
import './App.css';
import Home from './components/pages/HomePage/Home';
import Credits from './components/Credits';

import PageNotFound from './components/404/PageNotFound'
import { BrowserRouter as Router, Switch, Route ,Redirect} from 'react-router-dom';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
     
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/#nico' exact component={Home} />
        <Route path='/credits'exact component={Credits} />
        <Route path="/404" component={PageNotFound} />
                    <Redirect to="/404" />
      </Switch>
    
    </Router>
  );
}

export default App;
