import React from 'react';
import Lottie from 'react-lottie';
import Button from 'react-bootstrap/Button'
import Navbar from "../Navbar"
import animationData from './404_lottie.json';
export default function PageNotFound() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return(
<div  style={{display:"flex",justifyContent:"center",alignContent:"center",flexDirection:"column"}}>
<Navbar/>
<Lottie 
	    options={defaultOptions}
        height={"100%"}
        width={"100%"}
      />
     
</div>
  );
}




