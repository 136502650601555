import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import {Navbar,Nav} from 'react-bootstrap'

function NavigationBar() {
 


  return (
    <>
      <Navbar bg="dark" variant="dark">
    <Navbar.Brand href="/">Code Origin</Navbar.Brand>
    <Nav className="mr-auto">
      <Nav.Link href="/">Home</Nav.Link>
      <td
            
              onClick={()=> window.open("https://play.google.com/store/apps/dev?id=6606804330832821515", "_blank")}
            >
            <a href="#" > <Nav.Link>Our Apps</Nav.Link></a>
            </td>
     
    
    </Nav>
    
  </Navbar>
    </>
  );
}

export default NavigationBar;
