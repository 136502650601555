import screen_recorder_icon from '../../localresource/ic_screenrecorder.png'
export const creditNico = {
 
    appName: "Nico Video Player - Ultra HD Player, All Formats",
    iconUrl: "Screen Recorder - Record Screen In HD With Audio",
    appUrl: "https://play-lh.googleusercontent.com/JLVHKwT2645i3VELzxN8tbk_VovflG-39STQ_xsjEUJCK7b1hJFeSfxHFN-EXNs4DDM=s180-rw",
    data: [{
      item: <ul>
        <li><p> Purple sunset wallpaper
          Photo by <a  target="_blank" style={{ display: "table-cell" }} href="https://unsplash.com/@aleskrivec?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ales Krivec</a> on <a href="https://unsplash.com/s/photos/sunset-purple?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
        </li>
      </ul>
    },
    {
      item: <ul><li><p>  Snow sunrise
        Photo by <a  target="_blank" style={{ display: "table-cell" }} href="https://unsplash.com/@antipodos?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Michael Niessl</a> on <a href="https://unsplash.com/s/photos/sunrise?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </p>
      </li></ul>

    }, {
      item: <ul><li><p>  Blue background
        Photo by <a  target="_blank" style={{ display: "table-cell" }} href="https://unsplash.com/@ninjason?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jason Leung</a> on <a href="https://unsplash.com/s/photos/colors?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </p>
      </li>
      </ul>

    },
    {
      item: <ul>
        <li><a  target="_blank" style={{ display: "table-cell" }} href="https://lottiefiles.com/8311-loading">Loading gif</a>

        </li>
      </ul>
    }, {
      item: <ul>
        <li><p><a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/box'>Box vector created by stories - www.freepik.com</a>
        </p>
        </li>
      </ul>
    }

    ]
  

};
export const creditTimer= {
 
  appName: "Timer Stopwatch App - With Tones, Intervals, Laps",
  iconUrl: "Screen Recorder - Record Screen In HD With Audio",
  appUrl: "https://play-lh.googleusercontent.com/NH3nL9itZqhfd_SGHbFV16AEwjD_3cs7HfV-HINVZUhUiD-OUE0SzdLqSE9hq0r77w=s180-rw",
  data: [{
    item: <ul>
      <li><a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/calendar'>Calendar vector created by upklyak - www.freepik.com</a>
      </li>
    </ul>
  },
  {
    item: <ul><li>
    <a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/background'>Background vector created by tartila - www.freepik.com</a>
    </li></ul>

  }, {
    item: <ul><li><a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/time'>Time vector created by freepik - www.freepik.com</a>
    </li>
    </ul>

  },
  {
    item: <ul>
      <li>
      <a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/sports'>Sports vector created by tartila - www.freepik.com</a>
      </li>
    </ul>
  }, {
    item: <ul>
      <li><p><a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/health'>Health vector created by freepik - www.freepik.com</a>
      </p>
      </li>
    </ul>
  },
  {
    item: <ul>
      <li><p><a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/vectors/people'>People vector created by pikisuperstar - www.freepik.com</a>
      </p>
      </li>
    </ul>
  }

  ]


};
export const creditScreenRecorder
= {
 
  appName: "Screen Recorder - Record Screen In HD With Audio",
  iconUrl: "Screen Recorder - Record Screen In HD With Audio",
  appUrl: screen_recorder_icon,
  data: [{
    item: <ul>
      <li>
      <div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.flaticon.com/authors/good-ware"  title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  },
  {
    item: <ul><li><div>Icons made by <a  target="_blank" style={{ display: "table-cell" }}  href="https://icon54.com/" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </li></ul>

  }, {
    item: <ul><li><div>Icons made by  <a  target="_blank" style={{ display: "table-cell" }} target="_blank" style={{ display: "table-cell" }} href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

    </li>
    </ul>

  },
  {
    item: <ul>
      <li><div>Icons made by <a   style={{ display: "table-cell" }} href="https://www.flaticon.com/authors/kiranshastry" target="_blank" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

      </li>
    </ul>
  }, {
    item: <ul>
      <li>
<div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.flaticon.com/authors/chanut" title="Chanut">Chanut</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  }, {
    item: <ul>
      <li>
      <div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  },
  {
    item: <ul>
      <li>
      <div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  },
  {
    item: <ul>
      <li>
      <div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.flaticon.com/" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  }
  ,
  {
    item: <ul>
      <li>
      <a  target="_blank" style={{ display: "table-cell" }} href='https://www.freepik.com/photos/technology' target="_blank">Technology photo created by user18526052 - www.freepik.com</a>
      </li>
    </ul>
  },
  {
    item: <ul>
      <li>
      <div>Icons made by <a  target="_blank" style={{ display: "table-cell" }} href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </li>
    </ul>
  }
  ]


};