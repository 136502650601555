import React from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import Credits from "./Credits";

import playStore from "../components/localresource/googleplay.webp";
import appStore from "../components/localresource/appstore.png";

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  bgColor1,
  bgColor2,
  img,
  imageUrl,
  appUrl,
  alt,
  imgStart,
  iosLink,
}) {
  return (
    <div>
      <div
        style={{
          background: "linear-gradient(" + bgColor1 + "," + bgColor2 + ")",
          backgroundColor: bgColor1,
          alignContent: "center",
          alignSelf: "center",
          alignItems: "center",
        }}>
        <div
          style={{
            background: "linear-gradient(" + bgColor1 + "," + bgColor2 + ")",
            backgroundColor: bgColor1,
          }}
          className={
            lightBg ? "home__hero-section" : "home__hero-section darkBg"
          }>
          <div className="container">
            <div
              className="row home__hero-row"
              style={{
                display: "flex",
                flexDirection: imgStart === "start" ? "row-reverse" : "row",
              }}>
              <div className="col">
                <div className="home__hero-text-wrapper">
                  <div className="top-line">{topLine}</div>
                  <h1 className={lightText ? "heading" : "heading dark"}>
                    {headline}
                  </h1>
                  <p
                    className={
                      lightTextDesc
                        ? "home__hero-subtitle"
                        : "home__hero-subtitle dark"
                    }>
                    {description}
                  </p>

                  <a
                    style={{ display: "table-cell" }}
                    href={appUrl}
                    target="_blank">
                    {" "}
                    <img
                      style={{ width: 200, height: 80 }}
                      src="https://i.imgur.com/gxnBjp8_d.webp?maxwidth=640&shape=thumb&fidelity=medium"
                      alt={appUrl}
                      className="home__hero-img"
                    />
                  </a>
                  {iosLink != null ? (
                    <a
                      style={{ display: "table-cell" }}
                      href={iosLink}
                      target="_blank">
                      {" "}
                      <img
                        style={{ width: 170, height: 50 }}
                        src={appStore}
                        alt={appUrl}
                        className="home__hero-img"
                      />
                    </a>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="home__hero-img-wrapper">
                  <img
                    style={{ borderRadius: 16 }}
                    src={imageUrl}
                    alt={alt}
                    className="home__hero-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
