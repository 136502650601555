import scanner_cover from "../../localresource/scanner_cover.png";
import pdf_cover from "../../localresource/pdf_reader_2.webp";
import screen_recorder_cover from "../../localresource/screen_recorder.webp";
import timer_cover from "../../localresource/timer_cover_2.webp";
export const homeObjNico = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  bgColor1: "#44b7c5",
  bgColor2: "#00d4ff",
  background: "linear-gradient(rgba(101, 151, 229,0.5),transparent)",
  topLine: "",
  headline: "Nico Video Player - Ultra HD Player, All Formats",
  description:
    "Nico Video Player is a simple and light weight video playback tool which supports all formats. With an attractive user interface, the app gives you the best user experience and gives many features to easily manage and play videos. It supports 4K/ultra HD video files, and plays them with high-definition.",
  buttonLabel: "Get Started",
  imgStart: "",
  appUrl:
    "https://play.google.com/store/apps/details?id=com.codeorigin.nico.video.player.nicoplayer.hd.player4k.media.highres.hq.hdplayer.allformat.videoplayer.fast.mediaplayer",
  imageUrl:
    "https://play-lh.googleusercontent.com/wHD9QgnvuO5sfATuGWiAHf-9Ho0M212SXCGEszr3clGLrRThOSQFguwoJSBQU7baPXo=w720-h310-rw",
  img: "images/googleplay.png",
  alt: "Image",
};

export const homeObjTimer = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Timer Stopwatch App - With Tones, Intervals, Laps",
  description:
    "The perfect Timer - Stopwatch application with beautiful and clean digital user interface. Interval and gap timer function ideal for all your needs.",
  buttonLabel: "Learn More",
  appUrl:
    "https://play.google.com/store/apps/details?id=com.codeorigin.timer.stopwatch.app.custom.timerapp.countdowntimer.stopwatchlaps.sports.gym.track.time.stopwatchapp.laps.clock.progress",
  imgStart: "start",
  imageUrl: timer_cover,
  img: "images/svg-5.svg",
  bgColor1: "#86a0b3",
  bgColor2: "#020024",
  alt: "Image",
  iosLink: "https://apps.apple.com/in/app/timer-stopwatch-app/id1659253749",
};

export const homeObjScreenRecorder = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Screen Recorder - Record Screen In HD With Audio",
  description:
    "Screen Recorder is a simple to use, high-quality screen recorder app for you.",
  buttonLabel: "Start Now",
  imgStart: "",
  bgColor1: "#D4145A ",
  bgColor2: "#FBB03B",
  img: "images/screen_recorder.webp",
  appUrl:
    "https://play.google.com/store/apps/details?id=com.codeorigin.screenrecorder.video.rec.mobile.screen.recorder.camera.capture.videorecord.player.recorderwithsound.screengrab.withaudio.draw",
  imageUrl: screen_recorder_cover,
  alt: "Image",
};
export const homeObjScannner = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Text Scanner - Read From Images, Camera, Documents",
  description:
    "Text Scanner is an app to convert the image to text for saving it and sharing. You can extract the text accurately from any document/boards/books etc..",
  buttonLabel: "Start Now",
  imgStart: "start",
  bgColor1: "#02AABD",
  bgColor2: "#00CDAC",
  img: "images/svg-7.svg",
  appUrl:
    "https://play.google.com/store/apps/details?id=com.codeorigin.textscanner.imagetotext.text.scanner.ocr.converter.textreader.read.image.camera.document.recognition.words",
  imageUrl: scanner_cover,
  alt: "Image",
};
export const homeObejectPdf = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "PDF Reader - PDF Viewer, eBook Reader App",
  description:
    "PDF Reader is a perfect tool for you to read any PDF files on your device. You can view the list of all PDF files on your device in the app and also you can browse the PDF files through the file picker provided inside the app. Password protected PDF files are also supported. The app is fast, lightweight and easy to use",
  buttonLabel: "Start Now",
  imgStart: "start",
  bgColor1: "#c80204",
  bgColor2: "#ab3c36",
  img: "images/svg-7.svg",
  appUrl:
    "https://play.google.com/store/apps/details?id=com.codeorigin.pdfreader.pdf.viewer.reader.tool.basic.pdfviewer.pro.lite.office.document.free.office",
  imageUrl: pdf_cover,
  alt: "Image",
};
