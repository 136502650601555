import React from "react";
import "./Footer.css";
import { Button } from "../../Button";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaRedditAlien,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdFingerprint } from "react-icons/md";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Write your feedback to us at
        </p>
        <p className="footer-subscription-text">
          <a style={{ color: "white" }} href="mailto:support@codeorigin.tech">
            support@codeorigin.tech
          </a>
        </p>
        {/* <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='text'
              placeholder='Feedback'
            />
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>SEND</Button>
          </form>
        </div>
  */}
      </section>

      <section className="social-media">
        <div className="social-media-wrap">
          <Link to="/credits" id="linkid">
            {" "}
            <a
              id="linkid"
              style={{ display: "table-cell" }}
              href="codeorign.tech/credit"
              target="_blank">
              {" "}
              <small> Credits</small>
            </a>
          </Link>

          <td>
            {" "}
            <a
              target="_blank"
              id="linkid"
              href="https://play.google.com/store/apps/dev?id=6606804330832821515">
              <small>Developer Page</small>
            </a>
          </td>

          <small className="website-rights">2024 © Code Origin </small>
          <div className="social-icons">
            <td className="social-icon-link" aria-label="Twitter">
              <a
                id="linkid"
                style={{ display: "table-cell" }}
                href="https://x.com/codeorigin_tech"
                target="_blank">
                {" "}
                <FaXTwitter />
              </a>
            </td>
            <td className="social-icon-link" aria-label="LinkedIn">
              <a
                id="linkid"
                style={{ display: "table-cell" }}
                href="https://www.linkedin.com/company/codeorigin-tech"
                target="_blank">
                {" "}
                <FaLinkedin />
              </a>
            </td>
            <td className="social-icon-link" aria-label="Instagram">
              <a
                id="linkid"
                style={{ display: "table-cell" }}
                href="https://www.instagram.com/codeorigin.tech/"
                target="_blank">
                {" "}
                <FaInstagram />
              </a>
            </td>

            <td className="social-icon-link" aria-label="Youtube">
              <a
                id="linkid"
                style={{ display: "table-cell" }}
                href="https://www.youtube.com/channel/UCausM8VLovsw5Dtek5lyL0w"
                target="_blank">
                {" "}
                <FaYoutube />
              </a>
            </td>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
