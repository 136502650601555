import React from 'react';
import {Card,ListGroup,ListGroupItem }from 'react-bootstrap'
import CreditsPage from '../components/pages/Credits/CreditsPage'
import Footer from '../components/pages/FooterPage/Footer'
import './HeroSection.css'
function Credits() {
  return (
<div>

<section style={{width:"100%",height:"100%"}} >
          <CreditsPage />
        </section>
      <Footer/>

</div>


  );
}
export default Credits;
