import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import Navbar from "../../Navbar"
export default function CreditPageItem({
  app
}) {
  
  const list = []


    for (let i = 0; i < app.data.length; i++) {
      list.push(<ListGroup.Item>
        {app.data[i].item}
      </ListGroup.Item>)
    
  }
  return (
    <div style={{ paddingBottom: "60px", justifyContent: "center", alignContent: "center", display: "flex", alignSelf: 'center', flexDirection: "column", height: "100%", width: "100%" }}>

      <div style={{ flexDirection: "row", display: "flex", alignContent: "center", justifyContent: "center", marginBottom: "16px", marginTop: "16px" }}>
        <img style={{ width: 64, height: 64, marginRight: "16px", borderRadius: "32px" }} src={app.appUrl} />
        <h4 style={{ alignSelf: "center" }}>{app.appName}</h4>
      </div>
      <ListGroup variant="flush" style={{ display: "flex", alignContent: "center", justifyContent: "center", alignSelf: "center" }}>
        {list}
        
      </ListGroup>

    </div>
  );
}



