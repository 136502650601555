import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "../../Navbar";
import { creditNico, creditScreenRecorder, creditTimer } from "./CreditsData";
import CreditPageItem from "./CreditPageItem";
import ScrollToTop from "./ScrollToTop";
export default function CreditsPage() {
  return (
    <div>
      <Navbar />
      <ScrollToTop />
      <CreditPageItem app={creditNico} />
      <CreditPageItem app={creditTimer} />
      <CreditPageItem app={creditScreenRecorder} />
    </div>
  );
}
