import React from "react";
import HeroSection from "../../HeroSection";
import {
  homeObjNico,
  homeObjTimer,
  homeObjScannner,
  homeObjScreenRecorder,
  homeObejectPdf,
} from "./Data";
import Pricing from "../../Pricing";
import Footer from "../FooterPage/Footer";
import logo from "../../localresource/logo.webp";
class Home extends React.Component {
  render() {
    return (
      <>
        <section id="nico">
          <div
            style={{
              display: "flex",
              background: "#859FB2",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              color: "#859FB2",
              paddingTop: "30px",
            }}>
            <img
              style={{
                borderRadius: 16,
                width: 80,
                height: 80,
                justifyContent: "center",
              }}
              src={logo}
            />
            <h1
              style={{
                paddingLeft: 20,
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                alignItems: "center",
                color: "#fff",
              }}>
              Code Origin - Apps
            </h1>
          </div>
          <HeroSection {...homeObjTimer} />
          <HeroSection {...homeObjScreenRecorder} />
          <HeroSection {...homeObjNico} />
          <HeroSection {...homeObejectPdf} />
          <HeroSection {...homeObjScannner} />
        </section>
        <Footer />
      </>
    );
  }
}

export default Home;
